<template>
  <!-- This page supports infinite scroll -->
  <section
    class="messages-page-wrapper"
    v-infinite-scroll="loadMoreData"
    :infinite-scroll-disabled="isFetchMoreMessagesDisabled"
    :infinite-scroll-distance="10"
  >
    <div class="side">
      <am2-menu-vertical
        title="View by"
        :items="viewByOptions"
        :selected-key="activeFilter"
        @select="handleRecipientsMenuSelect"
        custom-padding="16px 16px"
        custom-head-padding="5px 5px 16px 0"
        min-width="188px"
      />
    </div>
    <div
      class="content"
    >
      <div :class="[{'md-max': $arMediaQuery.pageContent.maxWidth('sm')}, 'top-controls']">
        <am2-search
          class="search"
          v-model="searchString"
          placeholder="Search messages"
          @enter="() => serverSideSearch(searchString)"
          @clear="() => serverSideSearch(searchString)"
          :style="{width: searchBoxWidth}"
          data-test-id="top-search-input"
        />

        <div class="top-controls-right-container">
          <MessageTypeSelector
            :selected="currentMessageType"
            @onSelect="handleMessageTypeSelection"
          />
        </div>

        <div class="top-controls__export-button">
          <ar-simple-button
            text="Export report"
            outlined
            @click="displayExportModal = true"
          />
        </div>
      </div>

      <am2-message-preview-modal
        v-if="!!displayMessageData"
        data-test-id="message-preview-modal-from-list"
        :opened="displaySmsMessagePreviewModal"
        :message="displayMessageData"
        :message-list="displayMessageData.messageListOid"
        :provider="displayMessageData.provider"
        :email-template="savedEmail"
        :message-body="currentSelectedMessageMessageBody"
        :subject="displayMessageData.meta.messageBody.subject"
        :preview-text="displayMessageData.meta.messageBody.previewText"
        :scheduled-at="displayMessageData.scheduledAt"
        :sender-name="currentMessageSenderName"
        @close="displaySmsMessagePreviewModal = false"
        :facebook-page-name="currentSelectedMessageFacebookPageName"
        :facebook-page-image-src="currentSelectedMessageFacebookPageImageSrc"
      />
      <am2-email-preview-modal
        :opened="displayEmailMessagePreviewModal"
        :subject="currentSelectedMessageSubject"
        :preview-text="currentSelectedMessagePreviewText"
        :scheduled-at="scheduledAt"
        :sender-name="currentMessageSenderName"
        :email-html="templateHTML"
        :message="currentSelectedMessage"
        :is-loading="isFetchingPreviewTemplate"
        @close="displayEmailMessagePreviewModal = false"
        @variant-select="handleVariantSelect"
        show-share-link
      />

      <OverviewTables
        v-if="isOverviewPage"
        ref="overviewTable"
        :search-string="overviewSearch"
        :current-message-type="currentMessageType"
        @setMessageFilter="setMessageFilter"
        @cloneMessage="handleMessageAdded"
        @cancelMessage="handleMessageCancelled"
        @openMessagePreviewModal="openMessagePreviewModal"
      />
      <MessagesTable
        v-else
        :active-filter="activeFilter"
        @cloneMessage="handleMessageAdded"
        @cancelMessage="handleMessageCancelled"
        @openMessagePreviewModal="openMessagePreviewModal"
      />
    </div>
    <export-messages-modal :opened="displayExportModal" @close="displayExportModal = false" />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import MessagesTable from './messages-table.vue';
import OverviewTables from './overview-tables.vue';
import { arraysEqualUnsorted } from '@/utils/helpers/';
import MessageTypeSelector from './components/message-type-selector.vue'
import ExportMessagesModal from '@/components/modals/message/export-messages-modal/ExportMessagesModal.vue';

export default {
  name: 'Messages',
  layout: 'default',
  components: { MessagesTable, OverviewTables, MessageTypeSelector, ExportMessagesModal },

  data: () => ({
    searchString: '',
    currentMessageType: 'all',
    overviewSearch: '',
    activeFilter: 'all',
    displayEmailMessagePreviewModal: false,
    displaySmsMessagePreviewModal: false,
    displayMessageData: null,
    displayExportModal: false,
    viewByOptions: [
      {
        value: 'Overview',
        key: 'all'
      },
      {
        value: 'Sent',
        key: 'completed',
      },
      {
        value: 'Sending',
        key: 'in-progress',
      },
      {
        value: 'Drafts',
        key: 'draft',
      },
      {
        value: 'Scheduled',
        key: 'scheduled',
      },
      {
        value: 'Cancelled/Failed',
        key: 'failed',
      },
      {
        value: 'Archived',
        key: 'archived',
      },
    ],
    isFetchingPreviewTemplate: false,
    generatedHtml: null,
    scratchEmailWithInfo: '',
  }),

  watch: {
    messageListsOids(curr, old) {
      if (!arraysEqualUnsorted(curr, old) && curr.length > 0) {
        this.fetchMoreMessageLists();
      }
    }
  },

  computed: {
    ...mapState({
      messages: state => state.message.messages,
      isFetchingMessages: state => state.message.isFetchingMessages,
      isNoMoreMessages: state => state.message.isNoMoreMessages,
      hasFetchMessagesFailed: state => state.message.hasFetchMessagesFailed,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
      statsAB: state => state.message.statsAB,
      scratchEmailMessage: state => state.message.scratchEmailMessage,

      messageLists: state => state.messageList.messageLists,

      currentMessageSender: state => state.messageSender.currentMessageSender,
      savedEmail: state => state.message.savedEmail,
    }),
    ...mapGetters({
      injectInfoToScratchEmail: 'message/injectInfoToScratchEmail',
      getBeefreePreviewAndFooterHtmlForInjection: 'message/getBeefreePreviewAndFooterHtmlForInjection',
    }),

    isOverviewPage() {
      return this.activeFilter === 'all';
    },

    isFetchMoreMessagesDisabled() {
      return this.isOverviewPage ||
        this.isNoMoreMessages ||
        this.hasFetchMessagesFailed ||
        this.isFetchingMessages;
    },

    searchBoxWidth() {
      if (this.$arMediaQuery.pageContent.minWidth('md')) {
        return '330px';
      } else if (this.$arMediaQuery.pageContent.minWidth('sm')) {
        return 'calc(100% - 160px)'
      } else {
        return 'calc(100% - 80px)'
      }
    },

    /**
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentMessageSenderName() {
      if (this.currentMessageSender && this.currentMessageSender.additionalInfo && this.currentMessageSender.additionalInfo.senderName) {
        return this.currentMessageSender.additionalInfo.senderName;
      } else if (this.currentMessageSender && this.currentMessageSender.meta && this.currentMessageSender.meta.email && this.currentMessageSender.meta.email.senderName) {
        return this.currentMessageSender.meta.email.senderName;
      } else if (this.currentMessageList) {
        const messageListPromoterPropertyOid = this.currentMessageList && this.currentMessageList.meta.email ? this.currentMessageList.meta.email.promoterPropertyOid : null;
        const sender = this.messageSenders.find( item => item.oid === messageListPromoterPropertyOid);
        return sender && sender.additionalInfo ? sender.additionalInfo.senderName : '';
      } else {
        return null;
      }
      return null;
    },

    /**
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageFacebookPageImageSrc() {
      if (this.displayMessageData.provider !== 'facebook') {
        return null;
      }
      if (this.displayMessageData?.meta?.facebookMessenger?.imageSrc) {
        return this.displayMessageData?.meta?.facebookMessenger?.imageSrc;
      } else {
        return this.displayMessageData?.meta?.facebookMessenger?.imageSrc;
      }
    },

    /**
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageMessageBody() {
      if (this.displayMessageData.provider === 'email') {
        return null;
      }
      return this.displayMessageData?.meta.messageBody;
    },

    /**
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageFacebookPageName() {
      if (this.displayMessageData.provider !== 'facebook') {
        return null;
      }
      if (this.displayMessageData?.meta?.facebookMessenger?.pageName) {
        return this.displayMessageData?.meta?.facebookMessenger?.pageName;
      } else {
        return this.displayMessageData?.meta?.facebookMessenger?.pageName;
      }
    },

    /**
     * TO-DO: Duplicated with the logic in message view page
     * consider creating a module for message preview.
     */
    currentSelectedMessageMessageBody() {
      if (this.displayMessageData.provider === 'email') {
        return null;
      }
      return this.displayMessageData?.meta.messageBody;
    },

    currentMessageListsOid() {
      return this.messageLists.map(ml => ml.oid);
    },

    messageListsOids() {
      if (!this.messages.length) return [];

      return this.messages.reduce((acc, msg) => {
        const messageListOid = msg.meta.messageListOid;
        // Check that messageListOid is not null, and is not already loaded
        return messageListOid && !this.currentMessageListsOid.includes(messageListOid) ?
          [...acc, messageListOid] : acc;
      }, []);
    },

    // Fields for preview modal

    currentSelectedMessageSubject() {
      if (this.currentSelectedMessage?.abEmail && this.currentSelectedMessage?.abEmailType === 'subject') {
        return this.scratchEmailMessage.meta?.messageBody?.subject || ''
      }

      return this.currentSelectedMessage?.meta.messageBody.subject;
    },
    currentSelectedMessagePreviewText() {
      if (this.currentSelectedMessage?.abEmail && this.currentSelectedMessage?.abEmailType === 'subject') {
        return this.scratchEmailMessage.meta?.messageBody?.previewText || ''
      }
      return this.currentSelectedMessage?.meta.messageBody.previewText;
    },

    templateHTML() {
      if (this.currentSelectedMessage?.abEmail)
        return this.scratchEmailWithInfo;
      else if (this.currentSelectedMessage?.meta?.presentation?.templateType === 'html')
        return this.currentSelectedMessage?.meta?.presentation?.template
      return this.savedEmail
    },

    scheduledAt() {
      return this.displayMessageData?.scheduledAt
    },
  },

  created() {
    this['message/RESET_MESSAGES']();
  },

  beforeDestroy() {
    this['message/RESET_MESSAGES']();
    this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
  },

  methods: {
    ...mapActions([
      'message/FETCH_MORE_MESSAGES',
      'message/CANCEL_MESSAGES_FETCHING',
      'message/FETCH_BEEFREE_EMAIL_TEMPLATE_FROM_URL',
      'message/FETCH_MESSAGE',
      'messageList/FETCH_MORE_MESSAGE_LISTS',
      'messageSender/FETCH_CURRENT_MESSAGE_SENDER',
      'SHOW_CONFIRM',
      'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE'
    ]),
    ...mapMutations([
      'message/RESET_MESSAGES',
      'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
      'message/SET_SCRATCH_EMAIL_TEMPLATE',
      'message/SELECT_AB_EMAIL_MESSAGE',
      'message/SET_STATS_AB',
      'message/RESET_CURRENT_SELECTED_MESSAGE',
    ]),

    setMessageFilter(keyword) {
      this.activeFilter = keyword;
      this['message/CANCEL_MESSAGES_FETCHING']();
      this.reloadData();
    },

    handleMessageTypeSelection(option) {
      this.currentMessageType = option;
      this['message/CANCEL_MESSAGES_FETCHING']();
      this.reloadData();
    },

    handleRecipientsMenuSelect(item) {
      this.setMessageFilter(item.key);
      this['message/RESET_MESSAGES']();
    },

    async handleMessageAdded(message) {
      /**
       * New message can be in any kind, so let's reset filter in case
       * our promoters not see it.
       */
      await this.reloadData();
      this.setMessageFilter('all');

      // Let's scroll to top to see the new message
      window.scrollTo(0, 0);
    },

    async handleMessageCancelled(message) {
      this.reloadData();
    },

    async createNewEmail() {
      if(this.$arMediaQuery.window.maxWidth('xs')) {
        const response = await this.SHOW_CONFIRM({
          messageHtml: `You are able to create and edit emails using your desktop or tablet device.`,
          hideConfirmButton: true,
          cancelButtonText: 'Back',
        });
      } else {
        this.$router.push(`/message-center/messages/email/new/basic`);
      }
    },

    createNewMessage(type) {
      if (type === 'sms') {
        this.$router.push(`/message-center/messages/sms/new`);
      }
    },

    async loadMoreData(reload = false) {
      const status = this.activeFilter === 'all' ? null : this.activeFilter;
      const provider = this.currentMessageType === 'all' ? null : this.currentMessageType;

      this['message/FETCH_MORE_MESSAGES']({
        status,
        provider,
        reload,
        search: this.searchString,
      });
    },

    async reloadData() {
      this.loadMoreData(true);
    },

    async fetchMoreMessageLists() {
      if (this.messageListsOids.length === 0) return;

      this['messageList/FETCH_MORE_MESSAGE_LISTS']({
        oids: this.messageListsOids,
        userDefined: true,
      });
    },

    async serverSideSearch(search) {
      this.searchString = search;
      this.overviewSearch = search;
      this.reloadData();
    },

    handleSearchKeywordChange(keyword) {
      this.searchString = keyword;
      this.serverSideSearch();
    },

    async openMessagePreviewModal(data) {
      this.isFetchingPreviewTemplate = true;

      this.displayMessageData = data

      this['messageSender/FETCH_CURRENT_MESSAGE_SENDER']({ oid: data.meta?.email?.promoterPropertyOid })

      if (data.provider === 'email') {
        this.displayEmailMessagePreviewModal = true;
        await this['message/FETCH_MESSAGE'](data.oid);
        if (this.currentSelectedMessage?.abEmail) {
          this['message/SELECT_AB_EMAIL_MESSAGE']('A')
          await this.getScratchEmailWithInfo()
        } else if (this.currentSelectedMessage?.meta?.presentation?.templateType === 'html') {
          // Do nothing
        } else {
          await this['message/FETCH_BEEFREE_EMAIL_TEMPLATE_FROM_URL'](data.meta.templateResourceOid);
        }
      } else {
        this.displaySmsMessagePreviewModal = true;
      }

      this.isFetchingPreviewTemplate = false;
    },

    // Load html for preview
    handleVariantSelect() {
      this.getScratchEmailWithInfo();
    },

    async updateInjectedHtml() {
      this.generatedHtml = await this.getBeefreePreviewAndFooterHtmlForInjection(this.currentMessageSender);
    },

    async getScratchEmailWithInfo() {
      this.isFetchingTemplate = true;
      if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'beefree') {
        await this.updateInjectedHtml();
        const html = await this['emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE']({
          design: this.scratchEmailMessage?.meta?.presentation?.template,
          previewHtml: this.generatedHtml?.previewHtml || null,
          footerHtml: this.generatedHtml?.footerHtml || null
        });
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](html);

        this.scratchEmailWithInfo = html;
      } else if (this.scratchEmailMessage?.meta?.presentation?.templateType === 'html') {
        const html = this.scratchEmailMessage?.meta?.presentation?.template;
        this['message/SET_SCRATCH_EMAIL_TEMPLATE'](html);
        this.scratchEmailWithInfo = html;
      } else {
        // Unlayer
        this.scratchEmailWithInfo = this.injectInfoToScratchEmail(this.currentMessageSender);
      }
      this.isFetchingTemplate = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-page-wrapper {
  display: flex;
  margin-top: 56px;
  width: 100%;
  max-width: 1296px;

  .content {
    flex: 1;
    max-width: 1084px;
    transition: 0.3s all;
    margin-left: 0;
    margin-bottom: 50px;
    padding-right: 24px;
    padding-left: 24px;

    .top-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 50px 0 0;

      &.sm-max {
        margin: 35px 0 0;
        flex-direction: column;
        justify-content: unset;
        align-items: flex-start;
      }
    }
  }

  .side {
    max-width: 212px;
    padding-left: 24px;
  }
}
.top {
  &-controls {
    display: flex;
    flex-direction: row;
    align-items: center;

    .search {
      margin-right: 16px;
      width: 300px;
    }

    .expand-button {
      margin-left: 7px;
    }

    &__export-button {
      margin-left: auto;
    }

    &.md-max {
      width: 100%;

      .search {
        width: 100%;
        margin-right: 8px;
      }
    }
  }
}
</style>
