var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"integrations",style:({
    padding: _vm.$arMediaQuery.pageContent.minWidth('sm') ? '28px 30px 0' : '28px 12px 0',
  })},[_c('am2-message-list-modal',{attrs:{"is-show":_vm.shouldShowModal('eventbrite'),"loading":_vm.isFetchingEventbriteIntegration,"integration":_vm.selectIntegrationByProvider('eventbrite')},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'eventbrite' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('eventbrite')}}}),_vm._v(" "),_c('am2-ask-moshtix-access-token-modal',{attrs:{"is-show":_vm.shouldShowModal('moshtix'),"loading":_vm.isCreatingMoshtixIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'moshtix' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('moshtix')}}}),_vm._v(" "),_c('am2-ask-memberful-client-credentials-modal',{attrs:{"is-show":_vm.shouldShowModal('memberful'),"loading":_vm.isCreatingMemberfulIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'memberful' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('memberful')}}}),_vm._v(" "),_c('am2-ask-ticketek-s3-credentials-modal',{attrs:{"is-show":_vm.shouldShowModal('ticketek'),"loading":_vm.isCreatingTicketekIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'ticketek' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('ticketek')}}}),_vm._v(" "),_c('am2-ask-event-genius-access-token-modal',{attrs:{"is-show":_vm.shouldShowModal('event-genius'),"loading":_vm.isCreatingEventGeniusIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'event-genius' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('event-genius')}}}),_vm._v(" "),_c('am2-ask-dice-access-token-modal',{attrs:{"is-show":_vm.shouldShowModal('dice'),"loading":_vm.isCreatingDiceIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'dice' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('dice')}}}),_vm._v(" "),_c('am2-ask-shopify-access-token-modal',{attrs:{"is-show":_vm.shouldShowModal('shopify'),"loading":_vm.isCreatingShopifyIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'shopify' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('shopify')}}}),_vm._v(" "),_c('am2-see-tickets-uk-modal',{attrs:{"should-show":_vm.shouldShowModal('see-tickets-uk'),"is-waiting":_vm.isWorkatoWaiting,"integration":_vm.workatoIntegration,"provider-account":_vm.workatoProviderAccount},on:{"complete":function($event){return _vm.handleWorkatoComplete('see-tickets-uk')}}}),_vm._v(" "),_c('am2-axs-modal',{attrs:{"is-show":_vm.shouldShowModal('axs'),"is-waiting":_vm.isCreatingAxsIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'axs' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('axs')}}}),_vm._v(" "),_c('am2-mailchimp-modal',{attrs:{"should-show":_vm.shouldShowModal('mailchimp'),"is-waiting":_vm.isWorkatoWaiting,"integration":_vm.workatoIntegration,"provider-account":_vm.workatoProviderAccount},on:{"complete":function($event){return _vm.handleWorkatoComplete('mailchimp')},"cancel":function($event){return _vm.handleModalCancel('mailchimp')}}}),_vm._v(" "),_c('am2-tixr-modal',{attrs:{"should-show":_vm.shouldShowModal('tixr'),"is-waiting":_vm.isWorkatoWaiting,"integration":_vm.workatoIntegration,"provider-account":_vm.workatoProviderAccount},on:{"complete":function($event){return _vm.handleWorkatoComplete('tixr')},"cancel":function($event){return _vm.handleModalCancel('tixr')}}}),_vm._v(" "),_c('am2-tixr-modal-new',{attrs:{"is-show":_vm.shouldShowModal('tixr-new'),"loading":_vm.isCreatingTixrIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'tixr-new' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('tixr-new')}}}),_vm._v(" "),_c('am2-humanitix-modal',{attrs:{"is-show":_vm.shouldShowModal('humanitix'),"loading":_vm.isCreatingHumanitixIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'humanitix' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('humanitix')}}}),_vm._v(" "),_c('am2-ask-try-booking-credentials-modal',{attrs:{"is-show":_vm.shouldShowModal('try-booking'),"loading":_vm.isCreatingTryBookingIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'try-booking' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('try-booking')}}}),_vm._v(" "),_c('am2-ticketmaster-modal',{attrs:{"is-show":_vm.shouldShowModal('ticketmaster'),"loading":_vm.isCreatingTicketmasterIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'ticketmaster' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('ticketmaster')}}}),_vm._v(" "),_c('am2-axs-modal',{attrs:{"is-show":_vm.shouldShowModal('axs'),"loading":_vm.isCreatingAxsIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'axs' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('axs')}}}),_vm._v(" "),_c('am2-moshtix-exports-modal',{attrs:{"is-show":_vm.shouldShowModal('moshtix-exports-new'),"loading":_vm.isCreatingMoshtixExportsIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'moshtix-exports-new' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('moshtix-exports-new')}}}),_vm._v(" "),_c('am2-see-tickets-benelux-modal',{attrs:{"is-show":_vm.shouldShowModal('see-tickets-benelux'),"loading":_vm.isCreatingSeeTicketsBeneluxIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'see-tickets-benelux' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('see-tickets-benelux')}}}),_vm._v(" "),_c('am2-ticketbooth-modal',{attrs:{"is-show":_vm.shouldShowModal('ticketbooth'),"loading":_vm.isCreatingTicketboothIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'ticketbooth' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('ticketbooth')}}}),_vm._v(" "),_c('am2-ticketspice-modal',{attrs:{"is-show":_vm.shouldShowModal('ticketspice'),"loading":_vm.isCreatingTicketspiceIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'ticketspice' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('ticketspice')}}}),_vm._v(" "),_c('am2-showclix-modal',{attrs:{"is-show":_vm.shouldShowModal('showclix'),"loading":_vm.isCreatingShowclixIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'showclix' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('showclix')}}}),_vm._v(" "),_c('am2-ticket-tailor-modal',{attrs:{"is-show":_vm.shouldShowModal('ticket-tailor'),"loading":_vm.isCreatingTicketTailorIntegration},on:{"confirm":function($event){
  var i = arguments.length, argsArray = Array(i);
  while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleModalConfirm.apply(void 0, [ 'ticket-tailor' ].concat( argsArray ))},"cancel":function($event){return _vm.handleModalCancel('ticket-tailor')}}}),_vm._v(" "),_c('am2-category-tabs',{style:({
      marginBottom: '30px',
    }),attrs:{"items":_vm.categoryTabs},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_vm._v(" "),_vm._l((_vm.integrationItems),function(integrationItem,integrationItemIdx){return _c('section',{key:integrationItemIdx},[(integrationItem.enabled && integrationItem.categories.indexOf(_vm.selectedCategory) > -1)?_c('am2-integration-card',{staticClass:"integration-card",attrs:{"title":integrationItem.title,"description":integrationItem.description,"logo-icon-name":integrationItem.icon,"logo-color":integrationItem.iconColor,"loading":integrationItem.loading,"disabled":integrationItem.disabled,"connected":integrationItem.connected,"failed":integrationItem.failed,"connection":integrationItem.connection,"setup-url":integrationItem.setupUrl,"data-test-id":((integrationItem.key) + "-card")},on:{"manage":integrationItem.onManage,"connect":integrationItem.onConnect}}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }