var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditableFrame',{staticClass:"send-sms-wrapper",attrs:{"title":"Edit Action","subtitle":_vm.item.name,"has-save-button":"","save-button-disabled":_vm.savedButtonDisabled},on:{"close":function($event){return _vm.$emit('close')},"save":_vm.handleSave}},[_c('am2-message-preview-modal',{attrs:{"opened":_vm.displayPreviewModal,"provider":"sms","message-body":_vm.smsMessagePreview.body || ''},on:{"close":_vm.handlePreviewModalClose}}),_vm._v(" "),_c('ar-field',{staticClass:"type-section"},[_c('div',[_c('ar-text',{staticClass:"label u-margin-bottom-3",attrs:{"size":"14px","text":"What type of message are you sending?","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('am2-button-switch',{attrs:{"value":_vm.selectedType,"items":_vm.types},on:{"update":_vm.handleUpdateSelectedType}})],1),_vm._v(" "),_c('ar-text',{staticClass:"u-margin-top-3",style:({
        color: _vm.$arStyle.color.skyBlueGrey700,
      }),attrs:{"size":"13px","text":_vm.description[_vm.selectedType],"multiple-lines":""}})],1),_vm._v(" "),(_vm.selectedType === 'marketing')?_c('ar-field',{attrs:{"error-messages":[
      _vm.veeErrors.first('messageRecipient') ],"data-test-id":"automation-email-recipient-field"}},[_c('div',{staticClass:"recipient-section"},[_c('ar-text',{staticClass:"label",style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"size":"14px","text":"To","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('div',{staticClass:"add-to-list-dropdown-wrapper",attrs:{"data-test-id":"add-to-list-toggle"},on:{"click":_vm.toggleOptions}},[_c('ar-text',{staticClass:"add-to-list-copy",attrs:{"size":"14px","text":_vm.messageListText,"multiple-lines":"","max-lines":1,"line-height":"20px"}}),_vm._v(" "),_c('ArrowHead',{staticClass:"add-to-list-dropdown-arrowhead",class:_vm.listOpen && 'rotate',attrs:{"height":"8px","width":"10px","fill":"#8492a6"}})],1),_vm._v(" "),(_vm.listOpen)?_c('div',{staticClass:"list-search-wrapper"},[_c('am2-search',{staticClass:"add-to-list-search",attrs:{"placeholder":"Search lists...","data-test-id":"add-to-list-search"},model:{value:(_vm.listSearchText),callback:function ($$v) {_vm.listSearchText=$$v},expression:"listSearchText"}}),_vm._v(" "),_c('div',{staticClass:"message-list-wrapper"},[(_vm.noMatchingLists)?_c('div',{staticClass:"message-list-link no-list-found-wrapper"},[_c('div',{staticClass:"item-wrapper"},[_c('ar-text',{staticClass:"item-name",style:({ marginRight: '4px' }),attrs:{"size":"xs","text":"No matching list found","multiple-lines":"","max-lines":1,"line-height":"20px","weight":"normal"}})],1)]):_vm._e(),_vm._v(" "),_vm._l((_vm.filteredMessageList),function(list){return [_c('a',{key:list.oid,staticClass:"message-list-link",attrs:{"data-test-id":("add-to-list-anchor-" + (list.oid))},on:{"click":function () { return _vm.setSelectedListOid(list.oid); }}},[_c('div',{staticClass:"item-wrapper"},[_c('ar-text',{class:[
                    'list-name',
                    list.oid === _vm.selectedListOid && 'selected'
                  ],attrs:{"size":"xs","text":list.name,"multiple-lines":"","max-lines":1,"line-height":"20px","weight":list.oid === _vm.selectedListOid ? 'bold' : 'normal'}})],1)])]})],2)],1):_vm._e(),_vm._v(" "),_c('ar-text',{style:({
          color: _vm.$arStyle.color.skyBlueGrey700,
        }),attrs:{"text":"Automated marketing message recipients must be subscribed to a list","size":"xxs","multiple-lines":""}})],1)]):_vm._e(),_vm._v(" "),_c('ar-field',{attrs:{"error-messages":[
      _vm.veeErrors.first('message') ]}},[_c('div',{staticClass:"message-section"},[_c('div',{staticClass:"label-section"},[_c('ar-text',{attrs:{"size":"14px","text":"Message","weight":"bold","multiple-lines":"","max-lines":1}}),_vm._v(" "),_c('ar-link-button',{attrs:{"text":"Preview"},on:{"click":_vm.handlePreviewClick}})],1),_vm._v(" "),_c('SimpleMessageEditor',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
          max: _vm.scratchSimpleMessage.meta.messageBody ? _vm.scratchSimpleMessage.meta.messageBody.length + _vm.smsMessagePreview.charactersLeft : null,
        }),expression:"{\n          required: true,\n          max: scratchSimpleMessage.meta.messageBody ? scratchSimpleMessage.meta.messageBody.length + smsMessagePreview.charactersLeft : null,\n        }"}],ref:"simple-message-editor",attrs:{"value":_vm.scratchSimpleMessage.meta.messageBody,"data-vv-name":"messageBody"},on:{"input":_vm.handleMessageBodyInput}})],1)]),_vm._v(" "),_c('div',{staticClass:"preview-button-wrapper"},[(!!_vm.scratchSimpleMessage.meta.messageBody && !!_vm.scratchSimpleMessage.meta.messageBody.length >= 0)?_c('am2-characters-left-copy-section',{style:({
        marginTop: '10px',
        alignSelf: 'center'
      }),attrs:{"current-length":_vm.scratchSimpleMessage.meta.messageBody.length,"tooltip":"The character count below excludes the opt out message, which gets automatically appended to your SMS.","max-length":_vm.scratchSimpleMessage.meta.messageBody.length + _vm.smsMessagePreview.charactersLeft,"template":(_vm.smsMessagePreviewLength + " characters / " + _vm.smsMessagePreviewSegmentsCount + " SMS")}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"sms-charge-wrapper"},[_c('ar-checkbox',{attrs:{"label":"I agree to receive and pay an invoice for these SMS sends","label-typography-props":{multipleLines: true},"data-test-id":"automation-sendsms-accept-charge-checkbox"},model:{value:(_vm.costAccepted),callback:function ($$v) {_vm.costAccepted=$$v},expression:"costAccepted"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }