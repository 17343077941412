<template>
  <section class="message-conversions">
    <div class="message-conversions__content">
      <ar-snackbar
        v-if="!isCalculationsFinished"
        type="success"
        message="Conversion data is being calculated. Your data will be available soon."
        :style="{ width: '100%', marginBottom: '20px' }"
      />

      <div class="message-conversions__top">
        <ar-text class="message-conversions__top-text" text="Revenue attributed to this message" />

        <div class="message-conversions__top-filters">
          <am2-select-search-events
            ref="select-search-events"
            :value="selectedEvents"
            :pre-selected-events="preSelectedEvents"
            @changeSelectedOids="handleChangeOids"
          />

          <SelectLinkDropdown
            v-if="currentSelectedMessageProvider === 'email'"
            ref="select-link"
            :value="selectedLinks"
            @changeSelectedOids="handleChangeSelectedLinks"
          />

          <ar-simple-select
            class="message-conversions__date-filter"
            :items="dateFilterItems"
            :default-select-index="dateFilterDefaultIndex"
            @select="handleSelectDate"
          />
        </div>


      </div>


      <div class="message-conversions__cards">
        <conversion-stats-card
          :value="calculatedStatistic.revenue"
          :description="'Total conversion revenue'"
          :is-loading="isFetchingConversionStats || !isCalculationsFinished"
        />
        <conversion-stats-card
          :value="calculatedStatistic.totalOrders"
          :description="'Quantity of orders'"
          :is-loading="isFetchingConversionStats || !isCalculationsFinished"
        />
        <conversion-stats-card
          :value="calculatedStatistic.avgTickets"
          :description="'Tickets per recipient Avg.'"
          :is-loading="isFetchingConversionStats || !isCalculationsFinished"
        />
      </div>

      <template v-if="!selectedEvents.length">
        <no-content-section
          class="message-conversions__no-content"
          icon-name="ar-event-in-circle"
          header="Link your message to an event"
          body="Recipients who have purchased a ticket after receiving this message will display here"
          :button-text="'Add event'"
          @buttonClick="handleNoContentButtonClick"
        />
      </template>

      <template v-else>
        <div class="message-conversions__chart-wrapper">
          <conversions-chart />
        </div>

        <div class="message-conversions__table-section">
          <conversion-table />
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import ConversionStatsCard from "~/pages/message-center/messages/view/components/conversion-stats-card.vue";
import ConversionTable from "~/pages/message-center/messages/view/components/conversion-table.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import NoContentSection from "~/components/sections/no-content-section/NoContentSection.vue";
import ConversionsChart from "~/pages/message-center/messages/view/components/conversions-chart.vue";
import SelectLinkDropdown from '~/components/dropdowns/select-link/SelectLinkDropdown.vue'

import { formatNumberWithCommas } from "~/utils/helpers";

export default {
  name: 'MessageCenterMessageViewConversions',
  components: {
    NoContentSection,
    ConversionTable,
    ConversionStatsCard,
    ConversionsChart,
    SelectLinkDropdown,
  },
  data() {
    return {
      dateFilterItems: [
        { name: 'First 24 hours', value: 1 },
        { name: 'First 2 days', value: 2 },
        { name: 'First 3 days', value: 3 },
        { name: 'First 4 days', value: 4 },
        { name: 'First 5 days', value: 5 },
        { name: 'First 6 days', value: 6 },
        { name: 'First 7 days', value: 7 },
        { name: 'First 8 days', value: 8 },
        { name: 'First 10 days', value: 10 },
        { name: 'First 12 days', value: 12 },
        { name: 'First 14 days', value: 14 },
      ],
      preSelectedEvents: [],
      isLinkFeatureEnabled: true
    }
  },
  computed: {
    ...mapState({
      selectedEvents: state => state.message.conversionSelectedEvents,
      conversionStats: state => state.message.conversionStats,
      oid: state => state.route.params.oid,
      isFetchingConversionStats: state => state.message.isFetchingConversionStats,
      conversionTableCount: state => state.message.conversionTableCount,
      isCalculationsFinished: state => state.message.isCalculationsFinished,
      promoterOid: (state) => state.auth.account.promoterOid,
      links: state => state.message.messageLinks,
      timeWindow: state => state.message.conversionTimeWindow,
      selectedLinks: state => state.message.conversionSelectedLinks,
      currentSelectedMessage: state => state.message.currentSelectedMessage,
    }),

    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      regionSettingsCurrencySymbol: 'auth/regionSettingsCurrencySymbol',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),

    currentSelectedMessageProvider() {
      return this.currentSelectedMessage?.provider;
    },

    calculatedStatistic() {
      let revenue = 0
      let totalOrders = 0
      let avgTickets = 0
      this.conversionStats.forEach((item) => {
        revenue += item.indicators.revenue || 0
        totalOrders += item.indicators.totalOrders || 0
        avgTickets += item.indicators.avgTickets || 0
      })

      revenue = `${this.regionSettingsCurrencySymbol}${formatNumberWithCommas(revenue / 100)}`
      totalOrders = formatNumberWithCommas(totalOrders)
      avgTickets = formatNumberWithCommas(this.conversionStats.length ? avgTickets / this.conversionStats.length : 0)

      return { revenue, totalOrders, avgTickets}
    },

    dateFilterDefaultIndex() {
      return this.dateFilterItems.findIndex(item => item.value === this.timeWindow)
    },
  },
  watch: {
    isCalculationsFinished: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this['message/FETCH_CONVERSION_STATS_TIMESERIES'](this.oid);
        this['message/FETCH_CONVERSION_RECIPIENTS'](this.oid);
      }
    },
  },
  methods: {
    ...mapMutations([
      'message/SET_CONVERSIONS_SELECTED_EVENTS',
      'message/SET_CONVERSIONS_TIME_WINDOW',
      'message/CLEAR_CONVERSIONS_TIME_WINDOW',
      'message/SET_CONVERSIONS_LINK'
    ]),
    ...mapActions([
      'message/CONVERSION_SET_EVENTS',
      'message/FETCH_CONVERSION_STATS_TIMESERIES',
      'message/FETCH_CONVERSION_RECIPIENTS',
      'message/FETCH_CONVERSION_STATISTICS',
      'auth/FETCH_PERMISSIONS',
    ]),
    async handleSelectDate(item) {
      this['message/SET_CONVERSIONS_TIME_WINDOW'](item.value);
      await this['message/CONVERSION_SET_EVENTS'](this.oid);
      this['message/FETCH_CONVERSION_STATISTICS']({messageOid: this.oid});
    },
    async handleChangeOids(oids) {
      this['message/SET_CONVERSIONS_SELECTED_EVENTS'](oids);
      await this['message/CONVERSION_SET_EVENTS'](this.oid);
      this['message/FETCH_CONVERSION_STATISTICS']({messageOid: this.oid});
      this.fetchPreSelectedEvents()
    },
    handleNoContentButtonClick() {
      this.openSelectEventsDropdown()
    },
    openSelectEventsDropdown() {
      this.$refs['select-search-events'].$children[0].$children[0].$el.click()
    },
    async fetchPermissionsAndMaybeReroute() {
      await this['auth/FETCH_PERMISSIONS']();
      if (!this.isPermissionEnabled(['view-sales'])) {
        this.$router.push({
          path: `/message-center/messages/${this.oid}/view/overview`,
        });
      }
    },
    async handleChangeSelectedLinks(linksArray) {
      this['message/SET_CONVERSIONS_LINK'](linksArray);
      await this['message/CONVERSION_SET_EVENTS'](this.oid);
      this['message/FETCH_CONVERSION_STATISTICS']({messageOid: this.oid});
    },
    fetchPreSelectedEvents() {
      this.$axios.get(`/promoter/${this.promoterOid}/task/${this.oid}/attribution-selected-data`).then(data => {
        this.preSelectedEvents = data.data.events

        if (data.data.links) {
          this['message/SET_CONVERSIONS_LINK'](
            data.data.links
          )
        }
      })
    },
  },

  created() {
    if (!this.isFeatureEnabled(['conversions'])) {
      this.$router.push({
      path: '/audience',
    });
    }
  },

  mounted() {
    this.fetchPermissionsAndMaybeReroute();
    this['message/FETCH_CONVERSION_STATISTICS']({messageOid: this.oid, isSetFromSaved: true}).then(() => {
      if (this.selectedEvents.length) {
        this['message/FETCH_CONVERSION_STATS_TIMESERIES'](this.oid);
        this['message/FETCH_CONVERSION_RECIPIENTS'](this.oid);
      } else if (this.$route.query.method === 'open-select-events') {
        this.openSelectEventsDropdown()
      }
    });

    this.fetchPreSelectedEvents()
  },

  beforeDestroy() {
    this['message/SET_CONVERSIONS_SELECTED_EVENTS']([])
    this['message/CLEAR_CONVERSIONS_TIME_WINDOW']()
  },
}
</script>

<style lang="scss">
.message-conversions {
  display: flex;
  justify-content: center;

  &__content {
    max-width: 1200px;
    width: 100%;
    margin: 56px;

    @media (max-width: $xl-min) {
      margin: 0;
      padding: 56px;
    }
  }

  &__top {
    height: 48px;
    display: flex;
    align-items: center;

    @media (max-width: $lg-min) {
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      height: unset;
    }
   }

  &__top-text {
    margin-right: auto;
  }

  &__top-filters {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }

  &__date-filter {
    @media (max-width: $sm-min) {
      flex-basis: 100%;
    }
  }

  &__chart-wrapper {
    margin: 32px 0;
  }

  &__cards {
    display: flex;
    gap: 24px;
    margin-top: 32px;

    @media (max-width: $lg-min) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__no-content {
    margin-top: 32px;
    border: 1px solid $blueGrey500;
    box-shadow: unset;
  }
}
</style>
