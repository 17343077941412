<template>
    <div
      :class="{
        'rate-container': true,
        'multiple': multiple,
        'link': link,
      }"
      :data-test-id="rateTestId"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="onClick"
    >
      <div class="rate-header">
        <ar-text
          :text="`${rate}%`"
          size="lg"
          weigth="bold"
          :style="link && hover && {
            color: $arStyle.color.purple500,
          }"
        />
        <ar-text
          v-if="enableValue"
          :text="formattedValue"
          size="xs"
          :style="!(link && hover) && {
            color: $arStyle.color.blueGrey700,
          }"
        />
      </div>
      <div class="rate-body">
        <div class="rate-title-container">
          <ar-text
            class="rate-type"
            :text="title"
            size="xs"
            :style="!(link && hover) && {
              color: $arStyle.color.blueGrey700,
            }"
          />
          <ar-icon
            v-if="!!tooltip && tooltip.length > 1"
            name="tooltip-question-mark"
            class="u-margin-left-2"
            :color="$arStyle.color.purple500"
            v-tooltip.bottom="{
                content: tooltip,
                classes: 'align-left',
              }"
          />
        </div>
        <am2-gauge
          v-if="enableGauge"
          class="rate-gauge"
          fill-color="#7344c0"
          :bar-height="10"
          :border-radius="10"
          :data-test-id="gaugeTestId"
          :value="rate"
          :max="100"
          :track-color="gaugeTrackColor"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { toSlug } from '@/utils/helpers';
  import accounting from 'accounting';
  
  export default  {
    name: 'GaugeCard',
    props: {
      total: {
        type: Number,
        default: 0,
      },
      value: {
        type: Number,
        default: 0,
      },
      title: {
        type: String,
        default: '',
      },
      tooltip: {
        type: String,
        default: null,
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      link: {
        type: Boolean,
        default: false,
      },
      enableGauge: {
        type: Boolean,
        default: true,
      },
      enableValue: {
        type: Boolean,
        default: true,
      },
    },
  
    data() {
      return {
        hover: false,
      }
    },
  
    computed: {
      rate() {
        if (!this.total || !this.value) return 0
        const percentage = this.total === 0 ? 0 : (this.value / this.total) * 100;
        return percentage < 10 && percentage > 0 ? parseFloat(percentage.toFixed(1)) : Math.trunc(percentage);
      },
  
      formattedValue() {
        return accounting.formatNumber(this.value)
      },
  
      rateTestId() {
        return `overview-rate-${toSlug(this.title)}`;
      },
  
      gaugeTestId() {
        return `${this.rateTestId}-gauge`;
      },
  
      gaugeTrackColor() {
        return (this.link && this.hover) ? '#FFF' : '#F4F0FA';
      },
    },
  
    methods: {
      onClick() {
        this.$emit('click');
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .rate-container {
    width: 100%;
    flex: 1 100%;
    border-radius: 6px;
  
    &.link {
      cursor: pointer;
  
        &:hover {
          background-color: $purple100;
        }
    }
  
    &.multiple {
      margin: 0 50px;
    }
  
    &:only-child {
      margin-right: 0;
    }
  
    &:nth-of-type(1) {
      margin-left: 0;
    }
  
    .rate-header {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      margin: 0.5em 0 0.4em;
    }
  
    .rate-type {
      margin: 0.75em 0;
    }
  
    .rate-title-container {
      display: flex;
      flex-direction: row;
    }
  
    .rate-gauge {
      height: 12px;
      .track {
        height: 12px;
      }
      .fill {
        height: 12px;
      }
    }
  }
  </style>
  